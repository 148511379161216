export const userAnimals = [
    'Aardvark',
    'Ant',
    'Antelope',
    'Ape',
    'Axolotl',
    'Baboon',
    'Bear',
    'Beetle',
    'Boar',
    'Bobcat',
    'Bull',
    'Camel',
    'Cat',
    'Chameleon',
    'Crane',
    'Crow',
    'Dingo',
    'Donkey',
    'Dragon',
    'Eagle',
    'Falcon',
    'Flamingo',
    'Fox',
    'Frog',
    'Gibbon',
    'Gopher',
    'Gorilla',
    'Lemur',
    'Leopard',
    'Lion',
    'Llama',
    'Macaw',
    'Manatee',
    'Mantis',
    'Marmot',
    'Moose',
    'Ostrich',
    'Otter',
    'Ox',
    'Pelican',
    'Penguin',
    'Quail',
    'Rabbit',
    'Raccoon',
    'Rhino',
    'Scorpion',
    'Seal',
    'Shark',
    'Snake',
    'Squid',
    'Squirrel',
    'Stork',
    'Swan',
    'Turtle',
    'Wasp',
    'Wolf',
];

export const userColors = ['#FFB5E8', '#D5AAFF', '#62a8bd', '#6fb076', '#adad45', '#ba5b5b'];
